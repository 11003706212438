// ReadText.js
// Updated on 2024-10-14
// This component displays text content associated with a specific link ID using activation data.
// It handles loading states and error messages.

import React, { useEffect, useState } from 'react';
import './ReadText.css';
import EditText from './EditText'; // Import the EditText component

const ReadText = ({ link_Id, activationData }) => {
    const [textContent, setTextContent] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState('fa');
    const [isEditing, setIsEditing] = useState(false); // Declare editing state

    useEffect(() => {
        const detectLanguage = (text) => {
            const persianRegex = /[\u0600-\u06FF]/;
            return persianRegex.test(text) ? 'fa' : 'en';
        };

        if (activationData) {
            const TextToRead = activationData.TextToRead || activationData;
            setTextContent(TextToRead);
            setLanguage(detectLanguage(TextToRead));
        } else {
            setError('No activation data available.');
        }

        setLoading(false);
    }, [activationData]);

    return (
        <div className="read-text-container"> {/* Added container */}
            <div className="read-text-page">
                {loading && <div className="loading-message">در حال بارگذاری...</div>}
                {error && <div className="error-message">{error}</div>}
                
                {!loading && !error && (
                    <>
                        {isEditing ? (
                            <EditText 
                                link_Id={link_Id} // Corrected variable name
                                initialText={textContent} 
                                onSave={(updatedText) => {
                                    setTextContent(updatedText); // Update text content with the latest from the EditText component
                                    setIsEditing(false); // Exit editing mode after saving
                                }} 
                            />
                        ) : (
                            <>
                                <div className="text-content" lang={language}>
                                    {textContent}
                                </div>
                                <div className="edit-controls">
                                    <button className="edit-button" onClick={() => setIsEditing(true)}>
                                        ویرایش {/* Edit button */}
                                    </button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ReadText;

import React, { useState, useEffect } from 'react';
import Captcha from './Captcha'; // Import the Captcha component
import './EditText.css'; // Create a separate CSS file for EditText if needed

const EditText = ({ linkId, initialText, onSave }) => {
    const [inputValue, setInputValue] = useState(initialText); // Holds input value for actions
    const [captchaToken, setCaptchaToken] = useState(''); // State for captcha token
    const [remainingTime, setRemainingTime] = useState(900); // 15 minutes in seconds
    const [isEditing, setIsEditing] = useState(false); // Editing state
    const [error, setError] = useState(null); // Holds any error messages
    const [loading, setLoading] = useState(false); // Loading state

    // Update inputValue when initialText changes
    useEffect(() => {
        setInputValue(initialText);
    }, [initialText]);

    // Timer for remaining editing time
    useEffect(() => {
        let timer = null; // Timer variable
        if (isEditing && remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime((prev) => prev - 1); // Decrease remaining time
            }, 1000);
        } else if (remainingTime <= 0) {
            setIsEditing(false); // Disable editing when time is up
            console.log("Editing disabled: Time is up."); // Log when editing is disabled
        }
        return () => clearInterval(timer); // Cleanup timer on unmount or when editing is disabled
    }, [isEditing, remainingTime]);

    const handleCaptchaSuccess = (token) => {
        setCaptchaToken(token);
        setIsEditing(true); // Set editing mode
        setRemainingTime(900); // Reset remaining time to 15 minutes on entering edit mode
        console.log("CAPTCHA successful, token received:", token); // Log received CAPTCHA token
    };

    const handleTextChange = (event) => {
        setInputValue(event.target.value); // Update inputValue
        console.log("Text input changed:", event.target.value); // Log text input changes
    };

    const handleSave = async () => {
        if (!linkId || !inputValue.trim() || !captchaToken) {
            console.log("Save attempt failed: Missing input or CAPTCHA token."); // Log if inputs are missing
            return;
        }
    
        const payload = {
            linkId: linkId,
            input: inputValue, // Use inputValue instead of textContent
            token: captchaToken, // CAPTCHA token obtained from the Captcha component
        };
    
        console.log("Payload to be sent:", JSON.stringify(payload, null, 2)); // Log the payload before sending
        setLoading(true); // Set loading state to true
        setError(null); // Reset error state
    
        try {
            const response = await fetch(`https://d1worker.sapienssimple.workers.dev/edit/${linkId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Include any additional headers here if required
                },
                body: JSON.stringify(payload), // Ensure payload is JSON string
            });
    
            const result = await response.json();
            console.log("Response from server:", result); // Log server response
    
            if (result.success) {
                alert(result.message);
                onSave(result.updatedText || inputValue); // Call onSave prop with the updated text
                setIsEditing(false); // Exit editing mode after successful save
                console.log("Save successful: Updated text:", result.updatedText); // Log successful save
            } else {
                setError(result.message || 'خطای ناشناخته رخ داده است.'); // Handle server error response
                console.log("Server error:", result.message); // Log server error
            }
        } catch (err) {
            setError('عملیات ذخیره ناموفق بود. لطفا دوباره امتحان کنید.'); // Generic error message
            console.error("Save operation failed:", err); // Log the error
        } finally {
            setLoading(false); // Reset loading state
        }
    };
    

    return (
        <div className="edit-controls">
            {error && <div className="error-message">{error}</div>}
            <textarea
                className="editable-textarea"
                value={inputValue} // Controlled input
                onChange={handleTextChange} // Handle text change
                rows={5} // Adjust height as needed
            />
            <Captcha onSuccess={handleCaptchaSuccess} />
            <button className="save-button" onClick={handleSave} disabled={loading}>
                {loading ? 'در حال ذخیره...' : 'ذخیره'} {/* Show loading text when saving */}
            </button>
            {isEditing && remainingTime > 0 && (
                <div className="time-left">
                    {Math.floor(remainingTime / 60)}:
                    {('0' + (remainingTime % 60)).slice(-2)} باقی مانده {/* Remaining time display */}
                </div>
            )}
        </div>
    );
};

export default EditText;
